export const productCodes = {
    duratek: {
        "Configura Comfort Extra Small": "CHAIR-0-CM1-040",
        "Configura Comfort Small": "CHAIR-0-CM1-140",
        "Configura Comfort Small Tall": "CHAIR-T-CM1-140",
        "Configura Comfort Medium": "CHAIR-0-CM1-240",
        "Configura Comfort Medium Low Profile": "CHAIR-LP-CM1-240",
        "Configura Comfort Large": "CHAIR-0-CM1-340",
        "Configura Comfort Large Low Profile": "CHAIR-LP-CM1-340",
    },
    vinyl: {
        "Configura Comfort Extra Small": "CHAIR-0-CM1-030VN",
        "Configura Comfort Small": "CHAIR-0-CM1-130VN",
        "Configura Comfort Small Tall": "CHAIR-T-CM1-130VN",
        "Configura Comfort Medium": "CHAIR-0-CM1-230VN",
        "Configura Comfort Medium Low Profile": "CHAIR-LP-CM1-230VN",
        "Configura Comfort Large": "CHAIR-0-CM1-330VN",
        "Configura Comfort Large Low Profile": "CHAIR-LP-CM1-330VN",
    },
    commonHeights: {
        15: "KITHA-0-CM1-200X",
        17: "KITHA-0-CM1-000X",
        18: "KITHA-0-CM1-100X",
    },
    mediumLargeHeights: {
        17: "KITHA-0-CM1-200X",
        19: "KITHA-0-CM1-000X",
        20: "KITHA-0-CM1-100X",
    },
    depth: {
        16: "DA20-0-CM1-000",
        18: "DA18-0-CM1-000",
        20: "DA16-0-CM1-000",
    },
};
