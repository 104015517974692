// store.js
import { writable } from "svelte/store";
export const initVal = {
    vinyl: false,
    width: "",
    chairType: null,
    depth: "",
    height: "",
    pressure: "Visco",
    backrest: "Waterfall",
    lateralSupport: "None",
    dropdownArmrest: false,
    profiledHeadrest: false,
    selectorView: null,
};
export const configStore = writable(initVal);
